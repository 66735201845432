<template>
  <div id="app" class="app">
    <div class="container">
      <template v-if="mobile">
        <RentFormMob class="app__rent-form"/>

        <template v-if="!isLocationChange && !isCalendarChange">
          <PromoCode class="app__promo-code" />

          <AppChips class="app__chips" />
        </template>

        <CatalogMob class="app__catalog"/>
      </template>

      <template v-else>
        <RentForm class="app__rent-form"/>

        <PromoCode class="app__promo-code" />

        <AppChips class="app__chips" />

        <Catalog class="app__catalog"/>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

import RentForm from './components/RentForm.vue'
import RentFormMob from './components/RentFormMob.vue'
import PromoCode from './components/PromoCode.vue'
import AppChips from './components/AppChips.vue'
import Catalog from './components/Catalog.vue'
import CatalogMob from './components/CatalogMob.vue'

export default {
  name: 'App',
  components: {
    RentForm,
    RentFormMob,
    PromoCode,
    AppChips,
    Catalog,
    CatalogMob
  },
  data() {
    return {
      mobile: false
    }
  },
  created() {
    this.$store.dispatch('getCities').then(() => {
      let endDateFormated = moment(new Date(), 'DD.MM.YYYY').add(10, 'days').format('DD.MM.YYYY 12:00');
      this.$store.dispatch('changeEndDateTime', endDateFormated);

      let startDateFormated = moment(new Date(), 'DD.MM.YYYY').add(7, 'days').format('DD.MM.YYYY 12:00')
      this.$store.dispatch('changeStartDateTime', startDateFormated);

      this.$store.dispatch('setSity', this.cities[0]).then(() => {

        this.$store.dispatch('getLocations', this.cities[0].id).then(() => {

          this.$store.dispatch('setLocationStart', this.locations[0]).then(() => {

            this.$store.dispatch('setLocationEnd', this.locations[0]).then(() => {

              this.$store.dispatch('getCars').catch((error) => {
                Vue.$toast.open({
                  position: 'top-right',
                  message: error.response.data.message,
                  type: 'error',
                });
              })
            });
          });
        })
      })
    })

    this.isMobile();
    window.addEventListener("resize", this.isMobile);
  },
  destroyed() {
    window.removeEventListener("resize", this.isMobile);
  },
  computed: {
    cities() {
      return this.$store.getters.cities;
    },
    locations() {
      return this.$store.getters.locations;
    },
    isLocationChange() {
      return this.$store.getters.isLocationChange;
    },
    isCalendarChange() {
      return this.$store.getters.isCalendarChange;
    },
  },
  methods: {
    isMobile() {
      this.width = window.innerWidth;
      if( window.innerWidth <= 990 ) {
        this.mobile = true
      }
      else {
        this.mobile = false
      }
    },
  },

}
</script>

<style>
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 200;
  src: url("assets/fonts/Avenir/AvenirNextCyr-Light.woff") format("woff"), url("assets/fonts/Avenir/AvenirNextCyr-Light.ttf") format("truetype"); }

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 300;
  src: url("assets/fonts/Avenir/AvenirNextCyr-Regular.woff") format("woff"), url("assets/fonts/Avenir/AvenirNextCyr-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/Avenir/AvenirNextCyr-Medium.woff") format("woff"), url("assets/fonts/Avenir/AvenirNextCyr-Medium.ttf") format("truetype"); }

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 600;
  src: url("assets/fonts/Avenir/AvenirNextCyr-Demi.woff") format("woff"), url("assets/fonts/Avenir/AvenirNextCyr-Demi.ttf") format("truetype"); }

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 700;
  src: url("assets/fonts/Avenir/AvenirNextCyr-Bold.woff") format("woff"), url("assets/fonts/Avenir/AvenirNextCyr-Bold.ttf") format("truetype"); }
</style>

<style lang="sass">
.app
  height: 100%
  &__rent-form
  &__promo-code,
  &__chips
    margin-bottom: 20px
  &__catalog
    margin-bottom: 40px
  &__btn
    margin-bottom: 40px
  *,
  * > *
    box-sizing: border-box

.btn
  padding: 15px 19px
  display: block
  font-size: 14px
  color: #000000
  background: #FED01F
  border-radius: 5px
  box-shadow: 0px 1px 3px rgba(126, 105, 23, 0.66)
  &--more-cars
    width: 188px
    margin-left: auto
    margin-right: auto
    text-decoration: none

.container
  max-width: 852px
  margin: auto
  font-family: 'Avenir'

a
  // text-decoration: underline
  color: $color-yellow

.button
  display: flex
  align-items: center
  justify-content: center
  min-width: 250px
  height: 48px
  border: none
  border-radius: 3px
  background-color: #000000
  font-size: 16px
  font-weight: $fw-regular
  color: $color-white
  text-decoration: none
  &:hover
    color: $color-white
    text-decoration: none
  &--full
    width: 100%
  &--small
    height: 35px
    font-size: 12px
  &--yellow
    background-color: $color-yellow
    color: $color-black
    &:hover
      color: $color-black
  &--dark
    background-color: #666666
    color: $color-white
</style>
