<template>
  <div class="promo-code">
    <p class="promo-code__text">Есть промокод</p>

    <div class="promo-code__input-and-btn">
      <input
        v-model="promocode"
        type="text"
        class="promo-code__input"
      >

      <button
        @click="applyPromoCode"
        class="promo-code__btn"
      >
        Применить
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromoCode',

  data() {
    return {
      promocode: ''
    }
  },

  computed: {
    promocodeStore() {
      return this.$store.getters.promocode;
    }
  },

  methods: {
    applyPromoCode() {
      if (this.promocode !== this.promocodeStore && this.promocode) {
        this.$store.dispatch('setPromoCode', this.promocode);

        this.$store.dispatch('getCars');
      }
    }
  }
}
</script>

<style lang="sass">
.promo-code
  display: flex
  flex-wrap: wrap
  column-gap: 27.5px
  row-gap: 15px
  &__text
    padding: 0
    margin: 0
    &::before
      content: ''
      display: inline-block
      width: 16px
      height: 11.14px
      background: url(../assets/img/icons/promo-code.svg) no-repeat center
      margin-right: 11px
  &__input-and-btn
    display: flex
  &__input
    width: 123px
    max-width: 100%
    border: none
    border-bottom: 1px solid #DEDEDE
    margin-right: 15px
    transition: .2s ease
    &:focus
      outline: none
      border-bottom: 1px solid #bebebe
  &__btn
    padding: 4px 8px
    font-size: 12px
    border: 1px solid #CECECE
    border-radius: 3px
    background-color: transparent
    cursor: pointer
</style>