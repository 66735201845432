<template>
  <div>
    <div class="catalog__city">
      <div class="catalog__close" @click="clearStartLocation()"></div>
      <div class="catalog__city-title">Арендовать авто в городе</div>
      <div class="catalog__city-field">
        <input type="text" v-model="locationField">
        <div class="catalog__close" @click="clearStartField"></div>
      </div>
      <div class="catalog__city-list">
        <div v-if="cities && locationsStart.length > 0">
          <div class="catalog__city-item" v-for="location in locationsStart" v-bind:key="location.id" @click="setStartLocation(location)">
            <div class="catalog__city-icon" v-if="location.type_id == 1"><img src="@/assets/img/icons/airplane.svg" alt=""></div>
            <div class="catalog__city-icon" v-else><img src="@/assets/img/icons/mark-gray.svg" alt=""></div>
            <div class="catalog__city-name">
              {{location.name_ru}}
              <div class="catalog__city-sub" v-if="location.cost > 0">
                <div class="catalog__city-sub_title">Аэропортный сбор</div>
                <div class="catalog__city-sub_price">{{location.cost}} <i class="rouble-sign">₽</i></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="pt-3">Локаций не найдено</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'LocationScreenMob',
  data() {
    return {
      locationField: '',
      locationStartSuccess: false,
    }
  },
  props: ['step'],
  computed: {
    cities() {
      return this.$store.getters.cities;
    },
    locationsStart() {
      return this.cities.filter(location => location.name_ru.toLowerCase().includes(this.locationField.toLowerCase()));
    },
    locations() {
      return this.$store.getters.locations;
    },
    sity() {
      return this.$store.getters.sity;
    },
  },
  methods: {
    async setStartLocation(location) {
      await this.$store.dispatch('setSity', location).then(() => {
        this.locationField = location.name_ru;
        this.setLocationChange(false);

          this.$store.dispatch('getLocations', location.id).then(() => {

            this.$store.dispatch('setLocationStart', this.locations[0]).then(() => {

              this.$store.dispatch('setLocationEnd', this.locations[0]).then(() => {

                this.$store.dispatch('getCars').catch((error) => {
                  Vue.$toast.open({
                    position: 'top-right',
                    message: error.response.data.message,
                    type: 'error',
                  });
                })
              })
            })
          })
      });
    },
    clearStartField() {
      this.locationField = '';
    },
    clearStartLocation() {
      this.setLocationChange(false);
    },
    setLocationChange(status) {
      this.locationStartSuccess = false;
      this.$store.dispatch('setLocationChange', status);
    },
  },
}
</script>

<style lang="sass">
.catalog__city-field
  position: relative

.catalog__city-field input
  padding-right: 20px

.catalog__city-field .catalog__close
  right: 0
  top: 17px
  opacity: 0.6

.catalog__close
  width: 28px
  height: 28px
  background-size: 12px
  background-position: 50%
  margin-top: -8px
  margin-right: -8px

.rent-form__part--small span
  padding-top: 0 !important

.catalog
  &__categories
    display: flex
    overflow-x: scroll
    margin-bottom: 10px
    padding-bottom: 8px
    width: calc(100% + 15px)
    position: relative
    @media (min-width: $screen-sm)
      justify-content: center
    @media (min-width: $screen-lg)
      display: flex
      margin-bottom: 32px
      padding-bottom: 0
      overflow: visible
      width: 100%
  &__cars
    margin-bottom: 30px
    display: -webkit-flex
    display: -moz-flex
    display: -ms-flex
    display: -o-flex
    display: flex
    -webkit-flex-wrap: wrap
    -moz-flex-wrap: wrap
    -ms-flex-wrap: wrap
    -o-flex-wrap: wrap
    flex-wrap: wrap
    -webkit-justify-content: space-between
    -moz-justify-content: space-between
    -ms-justify-content: space-between
    -o-justify-content: space-between
    justify-content: space-between
    @media (min-width: $screen-lg)
      margin-bottom: 10px
  &__more
    display: flex
    justify-content: center
    @media (min-width: $screen-lg)
      margin-bottom: 45px
  &__close
    position: absolute
    width: 12px
    height: 12px
    background-image: url(../../assets/img/icons/close.svg)
    background-repeat: no-repeat
    background-size: contain
    right: 15px
    top: 25px
    z-index: 100
  &__city
    &-icon
      width: 29px
      min-width: 29px
    &-name
      font-size: 14px
      line-height: 18px
      letter-spacing: 0.3px
      width: 100%
      span
        font-size: 12px
    &-sub
      display: -webkit-flex
      display: -moz-flex
      display: -ms-flex
      display: -o-flex
      display: flex
      -webkit-justify-content: space-between
      -moz-justify-content: space-between
      -ms-justify-content: space-between
      -o-justify-content: space-between
      justify-content: space-between
      color: #666666
      font-size: 12px
      line-height: 17px
      -ms-align-items: center
      align-items: center
      &_price
        padding-top: 3px
    &-list
      padding-top: 22px
    &-item
      display: -webkit-flex
      display: -moz-flex
      display: -ms-flex
      display: -o-flex
      display: flex
      margin-bottom: 11px
    &-title
      font-size: 14px
      letter-spacing: 0.35px
    &-field
      margin-top: 42px
      input
        height: 44px
        width: 100%
        border: none
        outline: none
        font-size: 16px
        letter-spacing: 0.3px
        border-bottom: 1px solid $color-grey-light
</style>