<template>
  <div class="chips">
    <span
      @click="showHideDropDownLocations"
      class="chips__chip chips__chip--map-label"
    >
      <ul
        v-if="isDropDownLocations"
        v-click-outside="hide"
        class="chips__drop-down-list-locations"
      >
        <li>
          <span class="chips__drop-down-list-locations-close"></span>
        </li>

        <li
          v-for="location in locations"
          :key="location.id"
          @click="getCars(location)"
          :class="{ 'chips__drop-down-list-locations-item--active' : locationStart.id === location.id }"
          class="chips__drop-down-list-locations-item"
        >
          {{ location.name_ru }}
        </li>
      </ul>
    </span>

    <span v-if="loading.cities || loading.locations || !locationStart" class="chips__chip">Загрузка...</span>

    <SwiperContainer
      v-else
      :locations="locations"
      :locationStart="locationStart"
      @getCars="getCars"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import ClickOutside from 'vue-click-outside'

import SwiperContainer from '@/components/SwiperContainer.vue';

export default {
  name: 'AppChips',

  components: {
    SwiperContainer
  },

  directives: {
    ClickOutside
  },

  data() {
    return {
      isDropDownLocations: false
    }
  },

  computed: {
    locations() {
      return this.$store.getters.locations;
    },
    locationStart() {
      return this.$store.getters.locationStart;
    },
    loading() {
      return this.$store.getters.locationsLoading;
    },
  },

  mounted () {
    this.popupItem = this.$el
  },

  methods: {
    getCars(location) {
      this.$store.dispatch('setLocationStart', location).then(() => {

        this.$store.dispatch('setLocationEnd', location).then(() => {

          this.$store.dispatch('getCars').catch((error) => {
            Vue.$toast.open({
              position: 'top-right',
              message: error.response.data.message,
              type: 'error',
            });
          })
        });
      });
    },
    showHideDropDownLocations() {
      this.isDropDownLocations = !this.isDropDownLocations;

      if (document.body.clientWidth < 992) {
        document.body.style.overflow === "hidden"
        ? document.body.style.overflow = "auto"
        : document.body.style.overflow = "hidden";
      }
    },
    hide() {
      this.isDropDownLocations = false;
    }
  }
}
</script>

<style lang="sass">
ul
  padding: 0
  margin: 0
  list-style: none

.chips
  display: flex
  &::-webkit-scrollbar
    width: 0
  &__chip
    padding: 9px 12px
    font-size: 12px
    white-space: nowrap
    border: 1px solid #CECECE
    border-radius: 6px
    cursor: pointer
    &:not(:last-of-type)
      margin-right: 10px
    &--map-label
      position: static
      margin-right: 10px
      background: url(../assets/img/icons/map-label.svg) no-repeat center
      @media (min-width: $screen-md)
        position: relative
    &--active
      background-color: #DDF7DE
  &__drop-down-list-locations
    position: fixed
    padding-top: 50px
    top: 0
    right: 0
    bottom: 0
    left: 0
    font-size: 14px
    line-height: 3
    background-color: #ffffff
    z-index: 100
    @media (min-width: $screen-md)
      position: absolute
      padding-top: 0
      top: -20%
      left: 140%
      bottom: auto
      right: auto
      max-width: 320px
      font-size: 12px
      border-radius: 5px
      box-shadow: 10px 10px 10px #cacaca
    &-close
      position: absolute
      top: 20px
      right: 20px
      width: 20px
      height: 20px
      background: url(/widget/img/close.svg) no-repeat
      @media (min-width: $screen-md)
        display: none
    &-item
      padding: 5px 20px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      &:not(:last-of-type)
        border-bottom: 1px solid #c5c5c5
      &--active
        background-color: #ddf7de
</style>