<template>
  <div v-if="!isLocationChange && !isCalendarChange">
    <Loader class="w-100" v-if="loading.cars || loading.locations || loading.cities" />

    <div v-else>
      <div class="catalog__cars" v-if="cars && cars.vehicles.length > 0">
        <a
          v-for="car in cars.vehicles.slice(0, 8)"
          :key="car.id"
          @click="goToPage(car)"
          :class="{'catalog-car--multiple': car.vehicles}"
          href="javascript:void(0)"
          class="catalog-car"
        >
          <div v-if="car.vehicle">
            <div class="catalog-car__photo">
              <div class="catalog-car__photo-image" :style="`background-image: url(${car.vehicle.car_model_thumbnail})`"></div>
            </div>
            <div class="catalog-car__name">{{car.model}}</div>
            <div class="catalog-car__footer">
              <div class="catalog-car__price"><span class="catalog-car__price-old" v-if="car.vehicle.rate_per_day_old">{{formatPrice(car.vehicle.rate_per_day_old)}} <i class="rouble-sign">₽</i></span> {{formatPrice(car.vehicle.rate_per_day)}} <i class="rouble-sign">₽</i>/сутки</div>
            </div>
          </div>

          <div v-if="car.vehicles">
            <div class="catalog-car__photo">
              <div class="catalog-car__photo-image" :style="`background-image: url(${car.thumbnail})`"></div>
            </div>
            <div class="catalog-car__name">{{car.model}}</div>
            <div class="catalog-car__footer">
              <div class="catalog-car__price">от {{formatPrice(car.rate_per_day_min)}} <i class="rouble-sign">₽</i>/сутки</div>
            </div>
          </div>
        </a>
        <div class="catalog-car"></div>
      </div>

      <div class="pt-5 pb-5 mt-5 mb-5 w-100 text-center" v-else>
        <h2>Автомобилей не найдено</h2>
      </div>

      <a href="/search" class="app__btn btn btn--more-cars">Больше автомобилей</a>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader';

export default {
  name: 'Catalog',

  components: {
    Loader,
  },

  computed: {
    isCalendarChange() {
      return this.$store.getters.isCalendarChange;
    },
    isLocationChange() {
      return this.$store.getters.isLocationChange;
    },
    cars() {
      return this.$store.getters.cars;
    },
    loading() {
      return this.$store.getters.locationsLoading;
    },
    parameters() {
      return this.$store.getters.parameters;
    },
  },

  methods: {
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    goToPage(car) {
      const carId = car.vehicle ? car.vehicle.id : car.vehicles[0].id;

      const url = `/search?pick_up_location_id=${this.parameters.pick_up_location_id}&drop_off_location_id=${this.parameters.drop_off_location_id}&pick_up_datetime=${this.parameters.pick_up_datetime}&drop_off_datetime=${this.parameters.drop_off_datetime}&car=${carId}`;

      if (this.parameters.promocode) {
        document.location.href = `${url}&promocode=${this.parameters.promocode}`;
      } else {
        document.location.href = url;
      }
    }
  }
}
</script>