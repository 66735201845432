var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rent-form__field-box"},[(_vm.location === 'start')?_c('label',{staticClass:"rent-form__field-label",attrs:{"for":"place-from"}},[_vm._v("Хочу арендовать авто в городе:")]):_vm._e(),_c('div',{staticClass:"rent-form__field-group"},[_c('autocomplete',{ref:"ac",attrs:{"search":_vm.search,"get-result-value":_vm.getResultValue},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var rootProps = ref.rootProps;
        var inputProps = ref.inputProps;
        var inputListeners = ref.inputListeners;
        var resultListProps = ref.resultListProps;
        var resultListListeners = ref.resultListListeners;
        var results = ref.results;
        var resultProps = ref.resultProps;
return [_c('div',_vm._b({},'div',rootProps,false),[_c('input',_vm._g(_vm._b({staticClass:"form-control rent-form__location",attrs:{"type":"text"}},'input',inputProps,false),inputListeners)),(inputProps.value && inputProps.value.length > 2)?_c('i',{staticClass:"mx-icon-clear",on:{"click":_vm.clearValue}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1024 1024"}},[_c('path',{attrs:{"d":"M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"}})])]):_vm._e(),_c('ul',_vm._g(_vm._b({},'ul',resultListProps,false),resultListListeners),_vm._l((results),function(result,index){return _c('li',_vm._b({key:resultProps[index].id,class:("autocomplete-result result-type-" + (result.type_id))},'li',resultProps[index],false),[_vm._v(" "+_vm._s(result.name_ru)+" ")])}),0)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }