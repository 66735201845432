<template>
  <div>
    <Loader class="w-100" v-if="loading.cars || loading.locations || loading.cities" />

    <template v-else>
      <div class="catalog__cars" v-if="cars && cars.vehicles && cars.vehicles.length > 0">
        <a
          v-for="car in cars.vehicles.slice(0, 8)"
          :key="car.id"
          @click="goToPage(car)"
          :class="{ 'catalog-car--multiple': car.vehicles }"
          href="javascript:void(0)"
          class="catalog-car"
        >
          <div v-if="car.vehicle">
            <div class="catalog-car__photo">
              <div class="catalog-car__photo-image" :style="`background-image: url(${car.vehicle.car_model_thumbnail})`"></div>
            </div>
            <div class="catalog-car__name">{{car.model}}</div>
            <div class="catalog-car__footer">
              <div class="catalog-car__price"><span class="catalog-car__price-old" v-if="car.vehicle.rate_per_day_old">{{formatPrice(car.vehicle.rate_per_day_old)}} <i class="rouble-sign">₽</i></span> {{formatPrice(car.vehicle.rate_per_day)}} <i class="rouble-sign">₽</i>/сутки</div>
            </div>
          </div>

          <div v-if="car.vehicles">
            <div class="catalog-car__photo">
              <div class="catalog-car__photo-image" :style="`background-image: url(${car.thumbnail})`"></div>
            </div>
            <div class="catalog-car__name">{{car.model}}</div>
            <div class="catalog-car__footer">
              <div class="catalog-car__price">от {{formatPrice(car.rate_per_day_min)}} <i class="rouble-sign">₽</i>/сутки</div>
            </div>
          </div>
        </a>
      </div>

      <div class="pt-5 pb-5 mt-5 mb-5 w-100 text-center" v-else>
        <h2>Автомобилей не найдено</h2>
      </div>

      <a v-if="cars" href="/search" class="app__btn btn btn--more-cars">Больше автомобилей</a>
    </template>
  </div>
</template>

<script>
import Loader from '@/components/Loader';

export default {
  name: 'Catalog',

  components: {
    Loader,
  },

  computed: {
    cars() {
      return this.$store.getters.cars;
    },
    loading() {
      return this.$store.getters.locationsLoading;
    },
    parameters() {
      return this.$store.getters.parameters;
    },
  },

  methods: {
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    goToPage(car) {
      const carId = car.vehicle ? car.vehicle.id : car.vehicles[0].id;

      const url = `/search?pick_up_location_id=${this.parameters.pick_up_location_id}&drop_off_location_id=${this.parameters.drop_off_location_id}&pick_up_datetime=${this.parameters.pick_up_datetime}&drop_off_datetime=${this.parameters.drop_off_datetime}&car=${carId}`;

      if (this.parameters.promocode) {
        document.location.href = `${url}&promocode=${this.parameters.promocode}`;
      } else {
        document.location.href = url;
      }
    }
  }
}
</script>

<style lang="sass">
.catalog-car:hover
  -webkit-transform: scale(1.025) !important
  -ms-transform: scale(1.025) !important
  -o-transform: scale(1.025) !important
  transform: scale(1.025) !important

.catalog
  &__categories
    display: flex
    overflow-x: scroll
    margin-bottom: 10px
    padding-bottom: 8px
    width: calc(100% + 15px)
    position: relative
    @media (min-width: $screen-sm)
        justify-content: center
    @media (min-width: $screen-lg)
        display: flex
        margin-bottom: 32px
        padding-bottom: 0
        overflow: visible
        width: 100%
  &__cars
    margin-bottom: 30px
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 20px
    @media (min-width: $screen-sm)
      grid-template-columns: repeat(3, 1fr)
    @media (min-width: $screen-md)
      grid-template-columns: repeat(4, 1fr)
    @media (min-width: $screen-lg)
      margin-bottom: 10px
  &__more
    display: flex
    justify-content: center
    @media (min-width: $screen-lg)
      margin-bottom: 45px

.catalog-car
  display: flex
  margin-bottom: 15px
  padding: 0px
  flex-direction: column
  justify-content: space-between
  background-color: $color-white
  text-decoration: none!important
  -webkit-transition: all 200ms
  -o-transition: all 200ms
  transition: all 200ms
  &:hover
    -webkit-transform: scale(1.05)
    -ms-transform: scale(1.05)
    -o-transform: scale(1.05)
    transform: scale(1.05)

  @media (min-width: $screen-lg)
    margin-bottom: 19px

  &:hover
    color: $color-black
    text-decoration: none

  &--multiple
    position: relative
    .catalog-car__photo
      &:after
        content: ''
        display: block
        width: 100%
        height: 100%
        background-color: #C8C7CC
        border-radius: 6px
        z-index: 1
        top: 0
        left: 0
        position: absolute
        transform: translate(3px, 3px)
  &__top
    display: -webkit-flex
    display: -moz-flex
    display: -ms-flex
    display: -o-flex
    display: flex
    -ms-align-items: center
    align-items: center
    -webkit-justify-content: space-between
    -moz-justify-content: space-between
    -ms-justify-content: space-between
    -o-justify-content: space-between
    justify-content: space-between
    font-size: 12px
    color: $color-black
  &__photo
    height: 32.536vw
    margin-bottom: 9px
    position: relative
    @media (min-width: $screen-sm)
        height: 20vw
    @media (min-width: $screen-lg)
        height: 195px
    &-image
      width: 100%
      height: 100%
      background-size: 80%
      background-repeat: no-repeat
      background-position: center
      border-radius: 6px
      position: relative
      z-index: 2
      background-color: #fff
    &:before
      content: ''
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background: radial-gradient(58.71% 56.25% at 50% 56.25%, rgba(0, 0, 0, 0) 61.46%, rgba(0, 0, 0, 0.07) 97.92%)
      border-radius: 6px
      z-index: 3

  &__name
    font-size: 15px
    font-weight: $fw-regular
    color: $color-black

    @media (min-width: $screen-lg)
      font-size: 16px

  &__description
    font-size: 16px
    color: $color-grey-light
    margin-bottom: 20px

  &__price
    font-size: 15px
    font-weight: $fw-bold
    white-space: nowrap
    color: $color-black
    letter-spacing: 0.2px
    @media (min-width: $screen-lg)
      font-size: 14px
    &-old
      font-weight: $fw-regular
      font-size: 12px
      text-decoration: line-through
      letter-spacing: -1.3px
      @media (min-width: $screen-lg)
        font-size: 16px

  &__price5days
    font-size: 14px
    color: $color-black
    white-space: nowrap
    letter-spacing: 0.1px
    padding-top: 2px

.rouble-sign
  font-family: PT Sans
  font-style: normal

</style>