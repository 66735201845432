import axios from 'axios';
  let api = axios.create({
      baseURL: process.env.NODE_ENV == 'production' ? document.querySelector("meta[name='API_URL']").getAttribute('content') : 'https://npr111.05.testing.place/api/v1',
      headers: {
        'Content-Type': 'application/json',
        'App-Token': 'secret',
      },
    });

axios.interceptors.request.use((config) => {
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;
