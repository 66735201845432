<template>
  <div>
    <div v-if="!isLocationChange && !isCalendarChange">
      <form class="rent-form">
        <div class="rent-form__part rent-form__part--top" @click="setLocationChange(true, 1)">
          <div class="rent-form__fields">
            <div class="rent-form__field-box">
              <div class="rent-form__field-group" v-if="locationStart && !loading">
                <input type="text" class="form-control rent-form__location" disabled="" :value="`${sity.name_ru}`">
              </div>
              <div class="rent-form__field-group" v-else v-bind:class="{'rent-form__field-group--skeleton': loading}">
                <input type="text" class="form-control rent-form__location" disabled="" value="Хочу арендовать авто в городе:">
              </div>
            </div>
          </div>
        </div>
        <div class="rent-form__group" @click="setCalendarChange(true)">
          <div class="rent-form__part rent-form__part--small">
            <div class="rent-form__fields">
              <div class="rent-form__field-box">
                <div class="rent-form__field-group" v-if="dateStart">
                  <div class="rent-form__datetime">
                    <input class="form-control rent-form__date" :value="dateStart" disabled=""> <span>{{timeStart}}</span>
                  </div>
                </div>
                <div class="rent-form__field-group rent-form__field-group--skeleton  rent-form__field-group--skeleton-mini" v-else>
                  <div class="rent-form__datetime">
                    <input class="form-control rent-form__date" :value="dateStart" disabled=""> <span>{{timeStart}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rent-form__part rent-form__part--small">
            <div class="rent-form__fields">
              <div class="rent-form__field-box">
                <div class="rent-form__field-group" v-if="dateEnd">
                  <div class="rent-form__datetime">
                    <input class="form-control rent-form__date" :value="dateEnd" disabled=""> <span>{{timeEnd}}</span>
                  </div>
                </div>
                <div class="rent-form__field-group rent-form__field-group--skeleton  rent-form__field-group--skeleton-mini" v-else>
                  <div class="rent-form__datetime">
                    <input class="form-control rent-form__date" :value="dateEnd" disabled=""> <span>{{timeEnd}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div v-if="isLocationChange">
      <LocationScreenMob :step="step" />
    </div>
    <div v-if="isCalendarChange">
      <DateScreenMob />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import LocationScreenMob from '@/components/RentForm/LocationScreenMob.vue';
import DateScreenMob from '@/components/RentForm/DateScreenMob.vue';

export default {
    name: 'RentFormMob',
    data() {
      return {
        dateStartField: moment(new Date(), 'DD.MM.YYYY').add(7, 'days').format('DD.MM.YYYY'),
        dateEndField: moment(new Date(), 'DD.MM.YYYY').add(10, 'days').format('DD.MM.YYYY'),
        dateEndTime: '12:00',
        dateStartTime: '12:00',
        locationField: null,
        step: 1,
        loading: true,
      }
    },
    components: {
      LocationScreenMob,
      DateScreenMob,
    },
    computed: {
      isLoading() {
        return this.$store.getters.locationsLoading;
      },
      dateStart() {
        return this.$store.getters.dateStart;
      },
      dateEnd() {
        return this.$store.getters.dateEnd;
      },
      locationStart() {
        return this.$store.getters.locationStart;
      },
      timeStart() {
        return this.$store.getters.timeStart;
      },
      timeEnd() {
        return this.$store.getters.timeEnd;
      },
      cars() {
        return this.$store.getters.cars;
      },
      isLocationChange() {
        return this.$store.getters.isLocationChange;
      },
      isCalendarChange() {
        return this.$store.getters.isCalendarChange;
      },
      sity() {
        return this.$store.getters.sity;
      },
    },
    methods: {
      setLocationChange(status, step) {
        this.$store.dispatch('setLocationChange', status);
        this.step = step;
      },
      setCalendarChange(status) {
        this.$store.dispatch('setCalendarChange', status);
      },
      getDate(date) {
        return moment(date, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY');
      },
      getHour(date) {
        return moment(date, 'DD.MM.YYYY HH:mm:ss').format('HH:mm');
      },
      minDateToday(date) {
        return date < new Date(new Date().setHours(0, 0, 0, 0));
      },
      minDateEnd(date) {
        return date < new Date(moment(this.dateStart, 'DD.MM.YYYY').toDate());
      },
      changeStartDateTime(type) {
        let date = `${this.dateStartField} ${this.dateStartTime}`;
        this.$store.dispatch('changeStartDateTime', date).then(() => {
          this.$router.push({ query: Object.assign({}, this.$route.query, { pick_up_datetime: this.parameters.pick_up_datetime }) });
          if (type == 'time') {
            this.$store.dispatch('getCars').then(() => {
              this.loading = false;
            });
          } else {
            let momentDateStart = moment(this.dateStart, 'DD.MM.YYYY');
            let momentDateEnd = moment(this.dateEnd, 'DD.MM.YYYY');
            if (momentDateStart > momentDateEnd) {
              this.dateEndField = moment(this.dateStart, 'DD.MM.YYYY').add(this.cars.info.rent_period.d, 'days').format('DD.MM.YYYY');
              let date = `${this.dateEndField} ${this.dateEndTime}`;
              this.$store.dispatch('changeEndDateTime', date).then(() => {
                this.$router.push({ query: Object.assign({}, this.$route.query, { drop_off_datetime: this.parameters.drop_off_datetime }) });
                this.$store.dispatch('getCars').then(() => {
                  this.loading = false;
                });
              });
            } else {
              this.$store.dispatch('getCars').then(() => {
                this.loading = false;
              });
            }
          }
        });
      },
      changeEndDateTime() {
        let date = `${this.dateEndField} ${this.dateEndTime}`;
        this.$store.dispatch('changeEndDateTime', date).then(() => {
          this.$router.push({ query: Object.assign({}, this.$route.query, { drop_off_datetime: this.parameters.drop_off_datetime }) });
          this.$store.dispatch('getCars').then(() => {
            this.loading = false;
          });
        });
      },
    },
    mounted() {
      this.$store.dispatch('getCars').then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    watch: {
      dateStart(value) {
        this.dateStartField = value;
      },
      dateEnd(value) {
        this.dateEndField = value;
      },
      timeStart(value) {
        this.dateStartTime = value;
      },
      timeEnd(value) {
        this.dateEndTime = value;
      },
    },
}
</script>
