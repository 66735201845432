<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <span
        v-for="location in locations"
        :key="location.id"
        @click="getCars(location)"
        :class="{ 'chips__chip--active' : locationStart.id === location.id }"
        class="swiper-slide chips__chip"
      >
        {{ location.name_ru }}
      </span>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.css';

export default {
  name: 'SwiperContainer',

  props: {
    locations: {
      type: Array,
      required: true
    },
    locationStart: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      swiperSlider: null
    }
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.swiperSlider = new Swiper('.swiper-container', {
          slidesPerView: 'auto',
          mousewheel: {
            sensitivity: 5,
            releaseOnEdges: true
          },
        })
      }, 1000)
    })
  },

  methods: {
    getCars(location) {
      this.$emit('getCars', location);
    }
  }
}
</script>

<style>
.swiper-container {
  width: 100%;
  margin: 0;
}

.swiper-slide {
  width: auto;
}
</style>