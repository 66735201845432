import searchService from '@/services/search';
import moment from 'moment';

export default {
  state: {
    locations: [],
    locationsEnd: [],
    locationStart: null,
    locationEnd: null,
    dateStart: null,
    dateEnd: null,
    timeStart: null,
    timeEnd: null,
    cars: null,
    isLocationChange: false,
    isCalendarChange: false,
    cities: null,
    sity: null,
    loading: {
      locations: true,
      locationsEnd: true,
      cars: true,
      cities: true,
    },
    parameters: {
      pick_up_datetime: null,
      drop_off_datetime: null,
      pick_up_location_id: null,
      drop_off_location_id: null,
      promocode: null,
    },
  },
  getters: {
    isLocationChange: state => state.isLocationChange,
    isCalendarChange: state => state.isCalendarChange,
    locations: state => state.locations,
    locationsEnd: state => state.locationsEnd,
    locationStart: state => state.locationStart,
    locationEnd: state => state.locationEnd,
    dateStart: state => state.dateStart,
    dateEnd: state => state.dateEnd,
    timeStart: state => state.timeStart,
    timeEnd: state => state.timeEnd,
    locationsLoading: state => state.loading,
    cars: state => state.cars,
    parameters: state => state.parameters,
    cities: state => state.cities,
    sity: state => state.sity,
    promocode: state => state.parameters.promocode,
  },
  actions: {
    changeStartDateTime({ commit }, date) {
      commit('update_start_datetime', date);
    },
    changeEndDateTime({ commit }, date) {
      commit('update_end_datetime', date);
    },
    getLocations({ commit }, idLocation) {
      commit('locations_loading_start');
      return new Promise((resolve, reject) => {
        searchService.getLocations(idLocation)
          .then((response) => {
            commit('set_locations', response.data);
            commit('locations_loading_finish');
            resolve();
          })
          .catch(() => {
            reject();
            commit('locations_loading_finish');
          });
      });
    },
    getCars({ commit, state }) {
      commit('cars_loading_start');
        const parameters = {
          pick_up_datetime: state.parameters.pick_up_datetime,
          drop_off_datetime: state.parameters.drop_off_datetime,
          pick_up_location_id: state.parameters.pick_up_location_id,
          drop_off_location_id: state.parameters.drop_off_location_id,
          promocode: state.parameters.promocode,
        }
        if (!Object.values(parameters).every(o => o === null)) {
          return new Promise((resolve, reject) => {
            searchService.getCars(parameters)
              .then((response) => {
                commit('set_cars', response.data);
                resolve(response.data);
                commit('cars_loading_finish');
              })
              .catch((error) => {
                if (error.response && error.response.status === 422) {
                  commit('cars_loading_finish');
                  commit('clear_cars');
                }
                reject(error);
              });
          });
        } else {
          commit('cars_loading_finish');
        }
    },
    setLocationEnd({ commit }, location) {
      commit('set_location_end', location);
    },
    setLocationStart({ commit }, location) {
      if (location) {
        commit('set_location_start', location);
      }
    },
    clearCars({ commit }) {
      commit('clear_cars');
    },
    setLocationChange({ commit }, status) {
      commit('set_location_change', status);
    },
    setCalendarChange({ commit }, status) {
      commit('set_calendar_change', status);
    },
    getCities({ commit }) {
      commit('get_cities_loading_start');
        return new Promise((resolve, reject) => {
          searchService.getCities()
            .then((response) => {
              commit('set_cities', response.data);
              commit('get_cities_loading_finish');
              resolve();
            })
            .catch(() => {
              reject();
              commit('get_cities_loading_finish');
            });
        });
    },
    setSity({ commit }, sity) {
      commit('setSity', sity);
    },
    setPromoCode({ commit }, promocode) {
      commit('setPromoCode', promocode);
    }
  },
  mutations: {
    update_start_datetime(state, date) {
      let dateStart = moment(date, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY');
      let timeStart = moment(date, 'DD.MM.YYYY HH:mm').format('HH:mm');
      let pick_up_datetime = moment(date, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm');
      state.dateStart = dateStart;
      state.timeStart = timeStart;
      state.parameters.pick_up_datetime = pick_up_datetime;
    },
    update_end_datetime(state, date) {
      let dateEnd = moment(date, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY');
      let timeEnd = moment(date, 'DD.MM.YYYY HH:mm').format('HH:mm');
      let drop_off_datetime = moment(date, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm');
      state.dateEnd = dateEnd;
      state.timeEnd = timeEnd;
      state.parameters.drop_off_datetime = drop_off_datetime;
    },
    set_cars(state, cars) {
      state.cars = cars;
    },
    clear_cars(state) {
      state.cars = null;
    },
    set_locations(state, locations) {
      state.locations = locations;
    },
    set_location_start(state, location) {
      state.locationStart = location;
      state.parameters.pick_up_location_id = location.id;
    },
    set_location_end(state, location) {
      state.locationEnd = location;
      state.parameters.drop_off_location_id = location.id;
    },
    set_locations_end(state, locations) {
      state.locationsEnd = locations;
    },
    cars_loading_start(state) {
      state.loading.cars = true;
    },
    cars_loading_finish(state) {
      state.loading.cars = false;
    },
    locations_loading_start(state) {
      state.loading.locations = true;
    },
    locations_loading_finish(state) {
      state.loading.locations = false;
    },
    set_location_change(state, status) {
      state.isLocationChange = status;
    },
    set_calendar_change(state, status) {
      state.isCalendarChange = status;
    },
    set_cities(state, cities) {
      state.cities = cities;
    },
    get_cities_loading_start(state) {
      state.loading.cities = true;
    },
    get_cities_loading_finish(state) {
      state.loading.cities = false;
    },
    setSity(state, sity) {
      state.sity = sity;
    },
    setPromoCode(state, promocode) {
      state.parameters.promocode = promocode;
    }
  },
};
