<template>
  <form class="rent-form">
    <div class="rent-form__part">
      <div class="rent-form__fields">
        <rent-form-location-field location="start" v-bind:class="{disable: isLoading.cities}" />
      </div>
    </div>
    <div class="rent-form__part">
      <div class="rent-form__fields">
        <div class="rent-form__field-box">
          <label class="rent-form__field-label">Дата начала</label>
          <div class="rent-form__field-group">
            <div class="rent-form__datetime">
              <date-picker v-model="dateStartField" @change="changeStartDateTime('date')" format="DD.MM.YYYY" input-class="form-control rent-form__date" value-type="format" :disabled-date="minDateToday"></date-picker>
              <date-picker v-model="dateStartTime" @change="changeStartDateTime('time')" :disabled-time="minTimeToday" format="HH:mm" type="time" :time-picker-options="{start: '00:30', step:'00:30' , end: '23:30', format: 'HH:mm' }" input-class="form-control rent-form__time" value-type="format"></date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rent-form__part">
      <div class="rent-form__fields">
        <div class="rent-form__field-box">
          <label class="rent-form__field-label">Дата возврата</label>
          <div class="rent-form__field-group">
            <div class="rent-form__datetime">
              <date-picker v-model="dateEndField" @change="changeEndDateTime('date')" format="DD.MM.YYYY" input-class="form-control rent-form__date" value-type="format" :disabled-date="minDateEnd"></date-picker>
              <date-picker v-model="dateEndTime" @change="changeEndDateTime('time')" format="HH:mm" type="time" :time-picker-options="{start: '00:30', step:'00:30' , end: '23:30', format: 'HH:mm' }" input-class="form-control rent-form__time" value-type="format"></date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import RentFormLocationField from '@/components/RentForm/LocationField.vue';

import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

export default {
    name: 'RentForm',
    components: {
      RentFormLocationField,
      DatePicker,
    },
    data() {
      return {
        dateStartField: moment(new Date(), 'DD.MM.YYYY').add(7, 'days').format('DD.MM.YYYY'),
        dateEndField: moment(new Date(), 'DD.MM.YYYY').add(10, 'days').format('DD.MM.YYYY'),
        dateEndTime: '12:00',
        dateStartTime: '12:00',
      };
    },
    computed: {
      isLoading() {
        return this.$store.getters.locationsLoading;
      },
      dateStart() {
        return this.$store.getters.dateStart;
      },
      dateEnd() {
        return this.$store.getters.dateEnd;
      },
      locationStart() {
        return this.$store.getters.locationStart;
      },
      timeStart() {
        return this.$store.getters.timeStart;
      },
      timeEnd() {
        return this.$store.getters.timeEnd;
      },
      parameters() {
        return this.$store.getters.parameters;
      },
      cars() {
        return this.$store.getters.cars;
      },
    },
    methods: {
      minDateToday(date) {
        return date < new Date(moment(new Date(new Date()), 'DD.MM.YYYY').toDate());
      },
      minTimeToday(date) {
        let tomorrow = moment().add(1,'days').format('DD.MM.YYYY');
        if (this.dateStart == tomorrow) {
          return date < new Date(moment(new Date(new Date()), 'DD.MM.YYYY').toDate());
        }
      },
      minDateEnd(date) {
        return date < new Date(moment(this.dateStart, 'DD.MM.YYYY').add(1, 'days').toDate());
      },
      changeStartDateTime(type) {
        let date = `${this.dateStartField} ${this.dateStartTime}`;
        this.$store.dispatch('changeStartDateTime', date).then(() => {
          if (type == 'time') {
            this.$store.dispatch('getCars');
          } else {
            let momentDateStart = moment(this.dateStart, 'DD.MM.YYYY');
            let momentDateEnd = moment(this.dateEnd, 'DD.MM.YYYY');
            if (momentDateStart >= momentDateEnd) {
              this.dateEndField = moment(this.dateStart, 'DD.MM.YYYY').add(7, 'days').format('DD.MM.YYYY');
              let date = `${this.dateEndField} ${this.dateEndTime}`;
              this.$store.dispatch('changeEndDateTime', date).then(() => {
                this.$store.dispatch('getCars');
              });
            } else {
              this.$store.dispatch('getCars');
            }
          }
        });
      },
      changeEndDateTime() {
        let date = `${this.dateEndField} ${this.dateEndTime}`;
        this.$store.dispatch('changeEndDateTime', date).then(() => {
          this.$store.dispatch('getCars');
        });
      },
    },
    mounted() {
      this.$store.dispatch('getCars');
    },
    watch: {
      dateStart(value) {
        this.dateStartField = value;
      },
      dateEnd(value) {
        this.dateEndField = value;
      },
      timeStart(value) {
        this.dateStartTime = value;
      },
      timeEnd(value) {
        this.dateEndTime = value;
      },
    },
}
</script>

<style lang="sass">
.rent-form
  position: relative
  z-index: 100

.mx-datepicker
  width: auto !important

.mx-icon-calendar, .mx-icon-clear
  left: -1px
  color: #000
  top: 16px
  font-size: 20px
  width: 20px

@media screen and (min-width: 1000px)
  .rent-form__date
    background-image: none !important


.rent-form__time+i
  display: none !important

.rent-form__time+i+i
  display: none !important

.rent-form__field-box.disable
  opacity: 0.5
  position: relative

.rent-form__field-box.disable:after
  position: absolute
  content: ''
  display: block
  width: 100%
  height: 100%
  left: 0
  top: 0

.autocomplete .mx-icon-clear
  left: auto !important
  background-color: #fed01f

.autocomplete .mx-icon-clear svg
  width: 23px

$input-height: 35px

.rent-form
  margin-bottom: 18px
  @media (min-width: $screen-md)
    display: flex
    margin-top: 27px
    margin-bottom: 10px
  .form-control
    border-radius: 0
    border: none
    border-bottom: 1px solid rgba(0,0,0,0.1)
    color: $color-black
    background-color: transparent
    outline: none
    font-size: 14px
    height: 45px
    letter-spacing: 0.2px
    &:focus
      border-color: $color-black
    @media (min-width: $screen-md)
      height: $input-height
      font-size: 16px
      letter-spacing: 0
  &__group
    display: -webkit-flex
    display: -moz-flex
    display: -ms-flex
    display: -o-flex
    display: flex
    .rent-form__part
      width: 52%
      &:first-child
        width: 48%
  &__part
    display: flex
    background-color: $color-yellow
    padding: 0
    border-radius: 4px
    height: 45px
    &:last-child
      margin-right: 0
    &--top
      border-bottom-right-radius: 0px
      border-bottom-left-radius: 0px
    @media (min-width: $screen-md)
      width: 33.33%
      margin: 0 0 20px 0
      margin: 0 6px 20px 0
      padding: 15px 15px 19px 15px
      height: auto
    &--small
      padding-left: 13px
      padding-right: 8px
      span
        font-size: 11px
        padding-top: 3px
      @media (min-width: $screen-md)
        padding-left: 21px
      .form-control
        @media (max-width: $screen-md)
          border: none !important
      @media (max-width: $screen-md)
        border-top-right-radius: 0px
        border-top-left-radius: 0px
      &:first-child
        @media (max-width: $screen-md)
          border-bottom-right-radius: 0px
      &:last-child
        @media (max-width: $screen-md)
          border-bottom-left-radius: 0px
    &__icon
      display: flex
      align-items: center
      width: 36px
      height: $input-height
      margin-top: 30px
  &__fields
    width: 100%
    @media (min-width: $screen-md)
      display: flex
  &__location
    width: 100%
    padding-left: 47px
    background-image: url(../assets/img/icons/mark.svg)
    background-repeat: no-repeat
    background-position: 14px 11px
    @media screen and (min-width: $screen-md)
      padding-left: 32px
      background-position: 0px 6px
    @media screen and (min-width: $screen-lg)
  &__date
    width: 90px
    padding-left: 25px
    background-image: url(../assets/img/icons/calendar.svg)
    background-repeat: no-repeat
    background-position: 0px 12px
    padding-right: 0px
    font-size: 14px !important
    margin-right: 2px
    @media screen and (min-width: 360px)
      width: 110px
      padding-left: 32px
    @media screen and (min-width: $screen-lg)
      background-position: 0px 6px
      font-size: 15px !important
      width: 120px
      padding-right: 8px
      margin-right: 10px
  &__time
    width: 70px
    padding-left: 0px
    background-repeat: no-repeat
    background-position: 0px 6px
    padding-right: 0px
    font-size: 15px !important
    margin-right: 10px
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    background-image: url(../assets/img/icons/arrow-down-short-black.svg)
    background-size: 12px 7px
    background-position: 100% 50%
    background-repeat: no-repeat
    &:focus
      background-image: url(../assets/img/icons/arrow-up-short-black.svg)
  &__field-box
    margin: 0 0 10px 0
    &:last-child
      margin-right: 0
    @media (min-width: $screen-md)
      width: 100%
      margin: 0 12px 2px 0
  &__field-label
    font-weight: $fw-regular
    font-size: 14px
    margin: 0 0 1px 0
    letter-spacing: 0.4px
  &__field-group
      @media (min-width: $screen-md)
        display: flex
        align-items: center
      & > *
        margin-right: 15px
        &:last-child
          margin-right: 0
  &__place
    @media (min-width: $screen-md)
      width: 210px
  &__datetime
    width: 100%
    display: flex
    margin-bottom: 15px
    align-items: center
    @media (min-width: $screen-md)
      margin-bottom: 0
      justify-content: space-between
      align-items: flex-start
  &__days
    position: relative
    display: flex
    align-items: center
    height: $input-height
    font-size: 16px
    font-weight: $fw-bold
    padding: 15px
    white-space: nowrap
    &::before
      content: ''
      position: absolute
      width: 1px
      top: 7px
      bottom: 7px
      left: 0
      background-color: $color-black

.rent-form
  position: relative
  z-index: 100

.mx-datepicker
  width: auto !important

.mx-icon-calendar, .mx-icon-clear
  left: -1px
  color: #000
  top: 16px
  font-size: 20px
  width: 20px

@media screen and (min-width: $screen-md)
  .rent-form__date
    background-image: none !important

.rent-form__time+i
  display: none !important

.rent-form__time+i+i
  display: none !important

.rent-form__field-box.disable
  opacity: 0.5
  position: relative

.rent-form__field-box.disable:after
  position: absolute
  content: ''
  display: block
  width: 100%
  height: 100%
  left: 0
  top: 0

.autocomplete .mx-icon-clear
  left: auto !important
  background-color: #fed01f

.rent-form__part--middle
  border-radius: 0 !important

@media screen and (min-width: 1000px)
  .rent-form input
    -webkit-text-fill-color: #000 !important
    opacity: 1 !important

  .mx-table-date td, .mx-table-date th
    font-size: 14px !important

.rent-form__field-group--skeleton
  position: relative

.rent-form__field-group--skeleton:after
  content: ''
  display: none
  width: 100%
  height: 21px
  position: absolute
  top: 13px
  background-color: #e0e0e0
  width: 76%
  left: 47px
  background-size: 600px
  z-index: 100000
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px)
  animation: shine-lines 1.6s infinite linear

.rent-form__field-group--skeleton-mini:after
  content: ''
  display: none
  width: 100%
  height: 21px
  position: absolute
  top: 13px
  background-color: #e0e0e0
  width: 80%
  left: 18%
  background-size: 600px
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px)
  animation: shine-lines 1.6s infinite linear

.is-app .rent-form__field-group--skeleton:after
  background-color: #e0e0e0
  display: block

.is-app .rent-form__field-group--skeleton-mini:after
  background-color: #e0e0e0
  display: block

@keyframes shine-lines
  0%
    background-position: -100px
  40%, 100%
    background-position: 540px

@media screen and (max-width: 900px)
  .rent-form__field-box
    overflow: hidden

  .rent-form__part
    position: relative

  .rent-form__part--top:after
    content: ''
    display: block
    width: 90px
    height: 97%
    background: -moz-linear-gradient(left, rgba(254,208,31,0) 0%, rgba(254,208,31,1) 100%)
    background: -webkit-linear-gradient(left, rgba(254,31,255,0) 0%,rgba(254,208,31,1) 100%)
    background: linear-gradient(to right, rgba(254,208,31,0) 0%,rgba(254,208,31,1) 100%)
    position: absolute
    top: 0
    right: 0

  .rent-form__part--middle:after
    content: ''
    display: block
    width: 90px
    height: 97%
    background: -moz-linear-gradient(left, rgba(254,208,31,0) 0%, rgba(254,208,31,1) 100%)
    background: -webkit-linear-gradient(left, rgba(254,31,255,0) 0%,rgba(254,208,31,1) 100%)
    background: linear-gradient(to right, rgba(254,208,31,0) 0%,rgba(254,208,31,1) 100%)
    position: absolute
    top: 0
    right: 0

  .is-app .rent-form__part--middle:after
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(240,240,240,1) 100%)
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(240,240,240,1) 100%)
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(240,240,240,1) 100%)

  .is-app .rent-form__part--top:after
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(240,240,240,1) 100%)
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(240,240,240,1) 100%)
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(240,240,240,1) 100%)

  .catalog__categories::-webkit-scrollbar
    display: none

.rent-form .form-control:focus
  border-color: #000 !important
</style>