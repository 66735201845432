<template>
  <div class="rent-form__field-box">
    <label class="rent-form__field-label" for="place-from" v-if="location === 'start'">Хочу арендовать авто в городе:</label>
    <div class="rent-form__field-group">
      <autocomplete
      :search="search"
      :get-result-value="getResultValue"
      @submit="handleSubmit"
      ref="ac"
      >
        <template
          #default="{
            rootProps,
            inputProps,
            inputListeners,
            resultListProps,
            resultListListeners,
            results,
            resultProps
          }"
        >
          <div v-bind="rootProps">
            <input type="text"
              v-bind="inputProps"
              v-on="inputListeners"
              class="form-control rent-form__location"
            >

            <i class="mx-icon-clear" v-if="inputProps.value && inputProps.value.length > 2" @click="clearValue"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z"></path></svg></i>

            <ul v-bind="resultListProps" v-on="resultListListeners">
              <li
                v-for="(result, index) in results"
                :key="resultProps[index].id"
                v-bind="resultProps[index]"
                :class="`autocomplete-result result-type-${result.type_id}`"
              >
                {{ result.name_ru }}
              </li>
            </ul>
          </div>
        </template>
      </autocomplete>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

export default {
    name: 'RentFormLocationField',
    props: ['location', 'close'],
    components: {
      Autocomplete,
    },
    computed: {
      cities() {
        return this.$store.getters.cities;
      },
      locations() {
        return this.$store.getters.locations;
      },
      locationsStart() {
        return this.$store.getters.locationsStart;
      },
      locationStart() {
        return this.$store.getters.locationStart;
      },
      sity() {
        return this.$store.getters.sity;
      },
      parameters() {
        return this.$store.getters.parameters;
      },
      isLoading() {
        return this.$store.getters.locationsLoading;
      },
    },
    created() {
      this.sity ? this.$refs.ac.value = this.sity.name_ru : null;
    },
    methods: {
      search(input) {
        return this.cities.filter(location => location.name_ru.toLowerCase().includes(input.toLowerCase()));
      },
      getResultValue(result) {
        return result.name_ru;
      },
      async handleSubmit(result) {
        await this.$store.dispatch('setSity', result).then(() => {

            this.$store.dispatch('getLocations', result.id).then(() => {

              this.$store.dispatch('setLocationStart', this.locations[0]).then(() => {

                this.$store.dispatch('setLocationEnd', this.locations[0]).then(() => {

                  this.$store.dispatch('getCars').catch((error) => {
                    Vue.$toast.open({
                      position: 'top-right',
                      message: error.response.data.message,
                      type: 'error',
                    });
                  })
                })
              })
            })

          this.$emit('setStartLocation');
        });
      },
      clearValue() {
        this.$refs.ac.value = '';

        this.$store.dispatch('clearCars');
      }
    },
    watch: {
      sity(value) {
        this.$refs.ac.value = value.name_ru;
      },
    },
}
</script>

<style>
.autocomplete .mx-icon-clear {
  transform: scale(0.8);
  transform-origin: top left;
  top: 8px;
  opacity: 1;
  width: 24px !important;
  height: 24px;
  right: 0;
}

.autocomplete .mx-icon-clear svg {
  opacity: 0.6;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  margin-left: 0 !important;
}

.rent-form__location {
  padding-right: 35px;
  box-shadow: none !important
}

.autocomplete-result {
  background-image: url(../../assets/img/icons/mark-gray.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  font-size: 13px !important;
  padding-right: 10px !important;
}

.result-type-1 {
  background-image: url(../../assets/img/icons/airplane.svg);
  background-size: 20px;
  background-repeat: no-repeat;
}
</style>
