<template>
  <div class="catalog-date">
    <div class="catalog-date__header">
      <div class="catalog__close" @click="setDateTime"></div>
      <div class="catalog-date__column">
        <div class="catalog-date__label">Получение</div>
        <div class="catalog-date__value"><i class="js-start-date-value">{{getDate(dateStartField)}}</i> <span class="js-time-start"> {{timeStartField}}</span></div>
      </div>
      <div class="catalog-date__column">
        <div class="catalog-date__label">Возврат</div>
        <div class="catalog-date__value"><i class="js-end-date-value">{{getDate(dateEndField)}}</i> <span class="js-time-end"> {{timeEndField}}</span></div>
      </div>
    </div>
    <div class="catalog-date__calendar catalog-date__calendar--mobile">
      <date-picker v-model="datepicker" :inline="true" :range="true" :disabled-date="minDateToday" format="DD.MM.YYYY" range-separator=" - " value-type="format" @change="changeDate"></date-picker>
    </div>
    <div class="catalog-date__range">
      <div class="catalog-date__range-title">Начало</div>
      <div class="catalog-date__range-input">
        <input type="text" class="js-range-start">
      </div>
    </div>
    <div class="catalog-date__range">
      <div class="catalog-date__range-title">Окончание</div>
      <div class="catalog-date__range-input">
        <input type="text" class="js-range-end">
      </div>
    </div>
    <div class="catalog-date__button">
      <button class="button button--yellow button--full" @click="setDateTime">Готово</button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import $ from 'jquery';
import 'ion-rangeslider';
import DatePicker from 'vue2-datepicker';
import '@/assets/plugins/ion.rangeSlider.css';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

export default {
  name: 'DateScreenMob',
  components: {
    DatePicker,
  },
  data() {
    return {
      datepicker: null,
      timeStartField: null,
      timeEndField: null,
      dateStartField: null,
      dateEndField: null,
      timeValues:  [
          "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"
      ],
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters.locationsLoading;
    },
    dateStart() {
      return this.$store.getters.dateStart;
    },
    dateEnd() {
      return this.$store.getters.dateEnd;
    },
    timeStart() {
      return this.$store.getters.timeStart;
    },
    timeEnd() {
      return this.$store.getters.timeEnd;
    },
    parameters() {
      return this.$store.getters.parameters;
    },
    cars() {
      return this.$store.getters.cars;
    },
  },
  mounted() {
    this.timeStartField = this.timeStart;
    this.timeEndField = this.timeEnd;
    this.dateStartField = this.dateStart;
    this.dateEndField = this.dateEnd;
    this.datepicker = [this.dateStart,this.dateEnd];
    let startIndex = this.timeValues.indexOf(this.timeStart);
    let endIndex = this.timeValues.indexOf(this.timeEnd);
    $('.js-range-start').ionRangeSlider({
      values: this.timeValues,
      from: startIndex,
      to: 9,
      drag_interval: true,
      min_interval: 8,
      onChange: (data) => {
          this.timeStartField = data.from_value;
      }
    })
    $('.js-range-end').ionRangeSlider({
      values: this.timeValues,
      from: endIndex,
      to: 9,
      drag_interval: true,
      min_interval: 8,
      onChange: (data) => {
          this.timeEndField = data.from_value;
      }
    })
  },
  methods: {
    minDateToday(date) {
      return date < new Date(moment(new Date(new Date()), 'DD.MM.YYYY').toDate());
    },
    getDate(date) {
      return moment(date, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY');
    },
    getHour(date) {
      return moment(date, 'DD.MM.YYYY HH:mm').format('HH:mm');
    },
    changeDate() {
      if (this.datepicker[0] !== this.datepicker[1]) {
        this.dateStartField = this.datepicker[0];
        this.dateEndField = this.datepicker[1];
      } else {
        Vue.$toast.open({
          position: 'top-right',
          message: 'Дата окончания аренды не может быть равна дате начала',
          type: 'error',
        });
      }
    },
    setDateTime() {
      let dateEnd = `${this.dateEndField} ${this.timeEndField}`;
      let dateStart = `${this.dateStartField} ${this.timeStartField}`;
      this.$store.dispatch('changeStartDateTime', dateStart).then(() => {
        this.$store.dispatch('changeEndDateTime', dateEnd).then(() => {
          this.$store.dispatch('getCars');
          this.$store.dispatch('setCalendarChange', false);
        });
      });
    },
    setCalendarChange(status) {
      this.$store.dispatch('setCalendarChange', status);
    },
  },
  watch: {
    dateStart(value) {
      this.dateStartField = value;
    },
    dateEnd(value) {
      this.dateEndField = value;
    },
  },
}
</script>

<style lang="sass">
.catalog-date__calendar--mobile .mx-calendar+.mx-calendar
  display: none

.catalog-date__calendar--mobile .mx-calendar
  width: 100%

.catalog-date__calendar--mobile .mx-datepicker-main
  border: none

.catalog-date__calendar--mobile .mx-btn-icon-double-right
  display: none

.catalog-date__calendar--mobile .mx-btn-icon-double-left
  display: none

.catalog-date__calendar--mobile .mx-calendar-content .cell.active
  background-color: #fed01f
  color: #000

.catalog-date__calendar--mobile .mx-calendar-content .cell.in-range
  background-color: rgba(254, 208, 31, 0.49)

.catalog__close
  width: 28px !important
  height: 28px !important
  background-size: 12px !important
  background-position: 50% !important
  margin-top: -8px !important
  margin-right: -8px !important

.catalog-date
  &__header
    display: -webkit-flex
    display: -moz-flex
    display: -ms-flex
    display: -o-flex
    display: flex
    -webkit-justify-content: space-between
    -moz-justify-content: space-between
    -ms-justify-content: space-between
    -o-justify-content: space-between
    justify-content: space-between
    border-bottom: 1px solid $color-grey-light
    padding-bottom: 11px
  &__column
    width: 50%
    &:last-child
      padding-left: 5px
  &__label
    font-size: 12px
    letter-spacing: 0.3px
  &__value
    font-size: 14px
    padding-top: 4px
    i
      font-style: normal
    span
      font-size: 12px
      color: $color-grey
  &__calendar
    width: calc(100% + 30px)
    margin-left: -15px
    padding-top: 21px
    padding-bottom: 35px
  &__range
    margin-bottom: 25px
    &-title
      font-size: 15px
    &-input
      margin-top: -9px
  &__button
    padding-top: 57px

.irs--flat
  .irs
    &-line
      height: 3px
    &-min
      display: none
    &-max
      display: none
    &-bar
      background-color: $color-yellow
      height: 3px
    &-handle
      top: 17px
      & > i:first-child
        width: 20px
        left: 0
        height: 20px
        background-color: $color-yellow
        border-radius: 50%
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3)
    &-single
      top: 40px
      background: none
      color: #000
      font-size: 14px
      &:before
        display: none
  .irs--flat .irs-handle.state_hover > i:first-child, .irs--flat .irs-handle:hover > i:first-child
    background-color: $color-yellow
  .irs--flat
    .irs
      &-line
        height: 3px
      &-min
        display: none
      &-max
        display: none
      &-bar
        background-color: $color-yellow
        height: 3px
      &-handle
        top: 17px
        & > i:first-child
          width: 20px
          left: 0
          height: 20px
          background-color: $color-yellow
          border-radius: 50%
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3)
      &-single
        top: 40px
        background: none
        color: #000
        font-size: 14px
        &:before
          display: none

  .irs--flat .irs-handle.state_hover > i:first-child, .irs--flat .irs-handle:hover > i:first-child
    background-color: $color-yellow
</style>
