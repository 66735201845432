import api from './index';
import axiosCancel from 'axios-cancel';

axiosCancel(api, {
  debug: false,
});

export default {
  getLocations(idLocation) {
    return api.get(`/dict/locations?city_id=${idLocation}`);
  },
  getCars(parameters) {
    const requestId = 'search';
    if (parameters.promocode) {
      return api.get(`/search?pick_up_datetime=${parameters.pick_up_datetime}&drop_off_datetime=${parameters.drop_off_datetime}&pick_up_location_id=${parameters.pick_up_location_id}&drop_off_location_id=${parameters.drop_off_location_id}&promocode=${parameters.promocode}`, {
        requestId: requestId
      });
    } else {
      return api.get(`/search?pick_up_datetime=${parameters.pick_up_datetime}&drop_off_datetime=${parameters.drop_off_datetime}&pick_up_location_id=${parameters.pick_up_location_id}&drop_off_location_id=${parameters.drop_off_location_id}`, {
        requestId: requestId
      });
    }
  },
  getCities() {
    return api.get('/dict/cities');
  },
};
